import Layout from '../components/Layout';
import MasonryGallery from '../components/MasonryGallery';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import './portfolio.scss';

interface Props {
  data: any;
}

const Portfolio: FC<Props> = ({ data }: Props) => (
  <Layout>
    <MasonryGallery data={data} />
  </Layout>
);

export const query = graphql`
  query PortfolioPage($category: String) {
    contentfulGallery(category: { eq: $category }) {
      category
      gallery {
        max: gatsbyImageData(quality: 100, width: 1440)
        full: gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`;

export default Portfolio;
