import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC, useState } from 'react';
import Masonry from 'react-masonry-css';
import Lightbox from 'react-image-lightbox';
import './MasonryGallery.scss';

interface Props {
  data: any;
}

const BLOCK_CLASSNAME = 'masonry-gallery';

const MasonryGallery: FC<Props> = ({ data }: Props) => {
  const { gallery, category } = data.contentfulGallery;

  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = (): void => setIsOpen(!isOpen);
  const onOpen = (newIndex: number): void => {
    toggleIsOpen();
    setPhotoIndex(newIndex);
  };

  const mainSrc = gallery[photoIndex]?.max?.images.fallback.src;
  const nextSrc = gallery[(photoIndex + 1) % gallery.length]?.full?.images?.fallback?.src;
  const prevSrc =
    gallery[(photoIndex + gallery.length - 1) % gallery.length]?.full?.images?.fallback?.src;

  const breakpointColumnsObj = {
    default: 3,
    1440: 2,
    600: 1,
  };

  const mitarbeiterBreakPoints = {
    default: 6,
    1920: 4,
    1440: 3,
    1024: 2,
    600: 1,
  };

  const breakpointColumns =
    category === 'Mitarbeiter' ? mitarbeiterBreakPoints : breakpointColumnsObj;

  return (
    <div className={BLOCK_CLASSNAME}>
      <Masonry
        breakpointCols={breakpointColumns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {gallery.map((image: any, index: number) => {
          const fullImage = getImage(image.full);
          if (!fullImage) {
            return null;
          }
          return (
            <div onClick={() => onOpen(index)} key={index}>
              <GatsbyImage image={fullImage} loading="lazy" alt={image.title} />
            </div>
          );
        })}
      </Masonry>
      {isOpen && (
        <Lightbox
          mainSrc={mainSrc}
          onCloseRequest={toggleIsOpen}
          nextSrc={nextSrc}
          prevSrc={prevSrc}
          onMovePrevRequest={(): void =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={(): void => setPhotoIndex((photoIndex + 1) % gallery.length)}
        />
      )}
    </div>
  );
};

export default MasonryGallery;
